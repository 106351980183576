import { colors, MOBILE_BREAKPOINT } from "@util/constants";
import { Form, GridContainer } from "@util/standard";
import { Logo } from "@util/svg";
import React, { useState } from "react";
import styled from "styled-components";

const Entry = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault(); // Prevent the default form submission
    const formData = new FormData(event.target);
    const formObject = {};

    for (const [key, value] of formData.entries()) {
      formObject[key] = value;
    }
    console.log(formObject);

    const body = {
      form: formObject,
    };

    setSubmitted(true);

    const res = await fetch("/.netlify/functions/appraisal", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    console.log({ res });
  };

  return (
    <StyledWrapper>
      <LogoWrapper>
        <a href="/">
          <Logo color={"main"} />
        </a>
      </LogoWrapper>
      <h1>Request an Appraisal</h1>
      {submitted ? (
        <>
          <h2>Thank you for submitting your Appraisal request. We will be in touch soon</h2>
        </>
      ) : (
        <Form onSubmit={handleSubmit}>
          <GridContainer repeat={2} gap="15px" margin="0 0 15px" width="100%">
            <input name="firstName" type="text" placeholder="First Name" required />
            <input name="lastName" type="text" placeholder="Last Name" required />
          </GridContainer>
          <GridContainer repeat={2} gap="15px" margin="0 0 15px" width="100%">
            <input name="email" type="email" placeholder="Email" required />
            <input name="phone" type="tel" placeholder="Phone (+64)" required />
          </GridContainer>
          <GridContainer repeat={1} gap="15px" margin="0 0 15px" width="100%">
            <input name="address" type="text" placeholder="Address" required />
          </GridContainer>
          <GridContainer repeat={1} gap="15px" margin="0 0 15px" width="100%">
            <textarea name="notes" placeholder="Notes (Optional)" />
          </GridContainer>

          <button className="submit-button" type="submit">
            Submit
          </button>
        </Form>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 90%;
  margin: 20px auto;

  .submit-button {
    width: 100%;
    border: 1px solid ${colors.navy};
    border-radius: 8px;
    padding: 8px 0;
    background: ${colors.navy};
    color: ${colors.white};
  }
`;
const LogoWrapper = styled.div`
  padding: 25px 0 20px;
  min-width: 200px;

  svg {
    width: 166px;
    path {
      transition: 0.5s ease fill;
    }
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 23px 0 17px;
    svg {
      width: 145px;
    }
  }
`;

export default Entry;
